import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";

export function listarNotificacoes(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().listarNotificacoes, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function filtroListarSegmentacoes(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().filtroSegmentacoes, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function obterDetalhes(id, status) {
	let endereco = status != 3 ? `${id}&inativar=${status}` : id;
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(`${_rotasAPI().obterDetalhes}${endereco}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarTipoNotificacoes() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarTipoNotificacoes, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarDetalhesSegmentacoes(id, status) {
	let endereco = status != 3 ? `${id}&inativar=${status}` : id;

	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(`${_rotasAPI().listaSegmentacaoDetalhes}${endereco}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function adicionarNovaNotificacao(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().adicionarNotificacao, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function adicionarNovaSegmentacao(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().adicionarSegmentacao, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}
