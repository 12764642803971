<template>
	<div>
		<div class="produto-adicionar">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Cadastro de Notificação</h1>
					<formulario-notificacao :editar="false" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormularioNotificacao from "@/components/elementos/paginas/FormularioNotificacao"

export default {
	components: {
		FormularioNotificacao
	}
};
</script>

<style lang="scss">
.produto-adicionar {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
</style>
