import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";

export function listarAssistencias(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarAutorizadas + id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarGrupo() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarGrupos, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarComboGrupo() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().comboGrupo, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarAutorizadaGrupo(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(`${_rotasAPI().autorizadaGrupo}/?idGrupo=${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarRegioes() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarRegioes, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarTipoAutorizada() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarTipo, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarComboAutorizada() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarComboTipo, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarTipos() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarRegioes, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarAutorizadas(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarAutorizadas + id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarAutorizadasCompleto(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarAutorizadasCompleto + id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listaCargoPorAutorizada(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(`${_rotasAPI().listaCargoPorAutorizada}?tipoAutorizadaId=${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarAutorizadasCadastradas(data, pag, qtdPag) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().listarAutorizadasCadastradas + `?pagina=${pag}&quantidadeItemPorPagina=${qtdPag}`, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function listarDetalhesAutorizada(id) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarDetalhesAutorizada + id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function atualizarAutorizada(id, data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.put(_rotasAPI().atualizarAutorizada + id, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
				store.commit("SET_MODAL", {
					ativado: true,
					mensagem: resp.data.mensagem,
					tipo: "sucesso",
				})
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}

export function cadastrarAutorizada(data) {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.post(_rotasAPI().cadastrarAutorizada, data, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
				store.commit("SET_MODAL", {
					ativado: true,
					mensagem: resp.data.mensagem,
					tipo: "sucesso",
				})
			})
			.catch((erro) => {
				tokenExpirado(erro, erro.response.data.mensagem);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}